import React, { useEffect, useState } from "react";
import {
  useDispatch, useSelector
} from "react-redux";

import {
  Row, Col, Card, CardBody, CardTitle, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button 
} from "reactstrap";

import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import TableLoader from "components/Common/TableLoader";
import { withTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import moment from "moment";
import AddPredefinedAgreement from "./AddPredefinedAgreement";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import {
  fetchAccountTypes, fetchProducts, fetchPredefinedAgreements 
} from "store/actions";
import EditSharedIbModal from "pages/ClientDetail/Partnership/EditSharedIbModal";

function PredefinedAgreement(props) {
  const [sizePerPage, setSizePerPage] = useState(10);
  const [show, setIsShowing] = useState(false);
  const toggle = () => setIsShowing(!show);
  const [selectedIbAgreement, setSelectedIbAgreement] = useState(null);
  const dispatch = useDispatch();
  const { t } = props;
  const [sortField, setSortField] = useState({
    name: "createdAt",
    label: "Created At",
    direction: -1,
  });

  useEffect(() => {
    dispatch(fetchAccountTypes({
      type: "LIVE",
    }));
    dispatch(fetchProducts());
  }, []);

  useEffect(()=>{
    loadPredefinedAgrements(1, sizePerPage);
  }, [sizePerPage, sortField]);

  const loadPredefinedAgrements = (page, limit) => {
    dispatch(fetchPredefinedAgreements({
      page,
      limit,
      sort: sortField
    }));
  };

  const columns = [
    {
      text: t("Created At"),
      dataField: "createdAt",
      sortField: "createdAt",
      formatter: (item) => moment(item.createdAt).format("DD/MM/YYYY"),
    },
    {
      text: t("Name"),
      dataField: "title",
      sortField: "title",
    },
    {
      text:t("Created By"),
      dataField: "createdBy",
      sortField: "createdBy",
      formatter: (val)=> `${val.createdBy.firstName} ${val.createdBy.lastName}`
    },
    {
      text: t("Level"),
      dataField: "levels",
      sortField: "levels",
    },
    {
      text:t("Actions"),
      dataField: "actions",
      formatter: (val) => {
        console.log(val);
        return <Button onClick={()=>{
          setSelectedIbAgreement(val);
          setIsShowing(true);
        }}>
          {t("Edit")}
        </Button>;
      }
    }
  ];

  const agreements = useSelector((state) => state.predefinedIbAgrements.agreements);
  const { accountTypes } = useSelector((state) => state.tradingAccountReducer);
  const { products } = useSelector((state) => state.ibAgreements);
  const { _id } = useSelector((state) => state.clientReducer?.clientDetails);
  const markups = useSelector((state) => state.dictionaryReducer.markups);
  
  return (
    <React.Fragment>
      <MetaTags>
        <title>
          {t("Predefined Agreement")}
        </title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col>
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <CardTitle>
                    <h2>{t("Predefined Agreement")}</h2>
                  </CardTitle>
                  <AddPredefinedAgreement></AddPredefinedAgreement>
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover "
                      >
                        <Thead className="text-center table-light" >
                          <Tr>
                            {columns.map((column, index) =>
                              <Th data-priority={index} key={index}>
                                <span
                                  className={`color-primary ${column.sortField && "cursor-pointer"} d-flex justify-content-center align-items-center gap-3`}
                                  onClick={() =>
                                    column.sortField &&
                                    setSortField((prevSortField) => ({
                                      name: column.sortField,
                                      label: column.text,
                                      direction: prevSortField.direction === -1 ? 1 : -1,
                                    }))
                                  }
                                >
                                  {column.text}
                                  {column.sortField &&
                                    (((sortField.direction === 1 && sortField.name === column.sortField) ? (
                                      <div className="mt-1">
                                        <FaSortUp title="Ascending" />
                                      </div>
                                    ) : (
                                      <div className="mb-1">
                                        <FaSortDown title="Descending" />
                                      </div>
                                    )))}
                                </span>
                              </Th>
                            )}
                          </Tr>
                        </Thead>
                        <Tbody className="text-center">
                          {agreements.loading && <TableLoader colSpan={6} />}
                          {!agreements.loading && agreements.docs.map((row, rowIndex) =>
                            <Tr key={rowIndex}>
                              {columns.map((column, index) =>
                                <Td key={`${rowIndex}-${index}`}>
                                  {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                </Td>
                              )}
                            </Tr>
                          )}
                        </Tbody>
                      </Table>
                      <CustomPagination
                        {...agreements}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        onChange={loadPredefinedAgrements}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {show && selectedIbAgreement && <EditSharedIbModal
            show={show && selectedIbAgreement}
            toggle={toggle}
            accountTypes={accountTypes}
            products={products}
            agreement={selectedIbAgreement}
            markups={markups}
            predefinedStructure={true}
          />}
        </div>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(PredefinedAgreement);